@import '../../assets/style/variables.module.scss';

header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 3;
  background-color: rgba($background-color, 0.6);
  padding: 20px 300px;
  width: 100%;
  @media (max-width: 1300px) {
    padding: 10px 100px;
  }
  @media (max-width: 900px) {
    padding: 10px 70px;
  }
  @media (max-width: 769px) {
    padding: 10px 40px;
  }

  .banner {
    position: absolute;
    height: 7%;
    background-color: rgba($main-color, 0.6);
    background-blend-mode: overlay;
  }

  .banner-top-right {
    top: 30%;
    right: 0;
    width: 10%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 10px;
    @media (max-width: 1300px) {
      width: 5%;
    }
    @media (max-width: 769px) {
      width: 3%;
    }
  }

  .banner-top-left {
    top: 30%;
    width: 10%;
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 10px;
    @media (max-width: 1300px) {
      width: 5%;
    }
    @media (max-width: 769px) {
      width: 3%;
    }
  }

  .banner-bottom-right {
    bottom: 30%;
    right: 0;
    width: 12%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 20px;
    @media (max-width: 1300px) {
      width: 7%;
    }
    @media (max-width: 769px) {
      width: 5%;
    }
  }

  .banner-bottom-left {
    bottom: 30%;
    left: 0;
    width: 12%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 20px;
    @media (max-width: 1300px) {
      width: 7%;
    }
    @media (max-width: 769px) {
      width: 5%;
    }
  }

  img {
    height: 68px;
    object-fit: contain;
    @media (max-width: 769px) {
      height: 50px;
    }
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
    @media (max-width: 769px) {
      padding-right: 0px;
    }
    a {
      white-space: nowrap;
      font-weight: 500;
      margin-right: 20px;
      text-decoration: none;
      color: $secondary-color;
      font-size: 25px;
      transition: font-size 0.2s;
      text-shadow: 2px 2px 4px rgba($background-color, 1);
      @media (max-width: 769px) {
        font-size: 23px;
        margin-right: 15px;
        &:hover {
          font-size: 27px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
      &:hover {
        font-size: 30px;
        color: $main-color;
      }
      &.active {
        font-weight: 600;
      }

      .fa-solid {
        display: none;
        &:hover {
          color: $main-color;
        }
        @media (max-width: 850px) {
          display: inline;
        }
      }

      p {
        @media (max-width: 850px) {
          display: none;
        }
      }
    }
  }
}
