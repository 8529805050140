@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Rubik&display=swap');
@import './assets/style/variables.module.scss';

* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  object-fit: cover;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  margin: auto;
}

main {
  position: relative;
}
