@import '../../assets/style/variables.module.scss';

.home-container {
  position: relative;
  z-index: 1;
  background-image: url(../../assets/img/spaceship-red.webp);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  min-height: 190vh;
  width: 100%;
  margin-top: -100vh;
  overflow: hidden;
  .home-section {
    margin-top: 120vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 1080px) {
      flex-direction: column;
      margin-top: 110vh;
    }
    .title {
      color: white;
      width: 60%;
      text-align: center;
      margin-bottom: 30px;
      @media (max-width: 1300px) {
        width: 50%;
        margin: auto auto 30px auto;
      }
      @media (max-width: 1080px) {
        width: 90%;
      }
      h1 {
        word-wrap: break-word;
        font-size: 6rem;
        margin-bottom: 30px;
        @media (max-width: 769px) {
          font-size: 3rem;
        }
        span {
          color: $main-color;
        }
      }
      p {
        font-size: 2rem;
        width: 60%;
        text-align: center;
        margin: auto;
        @media (max-width: 769px) {
          font-size: 1.5rem;
          width: 90%;
        }
      }
    }
    .image-container {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      img {
        width: 500px;
        height: 500px;
        border-radius: 50%;
        filter: brightness(0.8);
        @media (max-width: 850px) {
          width: 350px;
          height: 350px;
        }
      }
      @media (max-width: 1300px) {
        width: 50%;
      }
      @media (max-width: 1080px) {
        width: 90%;
        margin: 0 auto 30px auto;
      }
    }
  }
}
