@import '../../assets/style/variables.module.scss';

.training {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .image-dev {
    width: 100%;
    height: 500px;
    margin-bottom: 20px;
    border-radius: 30px;
    border: none;
    @media (max-width: 769px) {
      height: 200px;
    }
  }
  h1 {
    font-size: 3rem;
    margin: 10px 0 30px 0;
  }
  p {
    margin: 10px 0;
    font-size: 1.5rem;
    @media (max-width: 769px) {
      font-size: 1.3rem;
    }
  }
  article {
    margin-bottom: 30px;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h2 {
      margin: 30px 0;
      font-size: 2rem;
    }
    i {
      color: $main-color;
      padding-left: 5px;
      &:hover {
        color: black;
      }
    }
    .date {
      font-style: italic;
      padding-bottom: 15px;
    }
    .tech {
      margin: 20px 0;
      i {
        color: $main-color;
        padding-right: 20px;
        font-size: 50px;
      }
    }
  }
}
