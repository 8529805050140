@import '../../assets/style/variables.module.scss';

.card {
  article {
    width: 400px;
    height: 550px;
    background-color: $main-color;
    border-radius: calc(0.1rem + 1vw);
    @media (max-width: 769px) {
      width: 100%;
    }
    @media (max-width: 430px) {
      width: 300px;
      height: 550px;
    }

    .image-container {
      position: relative;
      height: 80%;
      width: 100%;
      margin-bottom: 15px;

      img {
        display: block;
        border-top-left-radius: calc(0.1rem + 1vw);
        border-top-right-radius: calc(0.1rem + 1vw);
        height: 100%;
        width: 100%;
        overflow: hidden;
      }

      .image-hover {
        position: absolute;
        border-top-left-radius: calc(0.1rem + 1vw);
        border-top-right-radius: calc(0.1rem + 1vw);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(87, 82, 82, 0.7);
        opacity: 0;
        transition: opacity 0.3s ease;

        h2 {
          color: $secondary-color;
          text-align: center;
        }

        p {
          position: absolute;
          bottom: 10px;
          right: 0;
          left: 0;
          font-size: 20px;
          color: $secondary-color;
          font-weight: 600;
          margin: 0;
          text-align: center;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .link-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10%;
      margin-bottom: 15px;
      a {
        width: 40%;
        button {
          cursor: pointer;
          padding: 10px 0;
          width: 100%;
          background-color: $secondary-color;
          border: 2px solid $secondary-color;
          border-radius: calc(0.1rem + 1vw);
          &:hover {
            background-color: $main-color;
            color: $secondary-color;
            border: 2px solid $secondary-color;
            i {
              color: $secondary-color;
            }
          }
          i {
            font-size: 20px;
            color: $main-color;
          }
        }
      }
    }

    .tech-container {
      color: $secondary-color;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      span {
        font-size: 20px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

    .modal-content {
      position: relative;
      background-color: #fff;
      margin: 15% auto;
      padding: 20px;
      width: 80%;
      max-width: 800px;
      border-radius: 15px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      @media (max-width: 769px) {
        max-width: 90%;
      }
      p {
        width: 100%;
        min-height: unset;
      }

      .modal-pic-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        img {
          width: 700px;
          height: auto;
          border-radius: 15px;
          max-width: 90%;
        }
      }
    }

    .close {
      position: absolute;
      top: 10px;
      right: 15px;
      color: #aaa;
      font-size: 28px;
      font-weight: bold;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $main-color;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
