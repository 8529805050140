@import '../../assets/style/variables.module.scss';

.contact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
  background-color: $background-color;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
  .text-container {
    text-align: center;
    font-size: 2rem;
    width: 50%;
    color: $secondary-color;
    @media (max-width: 1080px) {
      width: 80%;
      text-align: center;
      padding: 30px 0;
    }
    span {
      color: $main-color;
    }
  }
  form {
    width: 30%;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    color: $secondary-color;
    border: 2px solid $main-color;
    border-radius: calc(0.1rem + 1vw);
    padding: 20px;
    @media (max-width: 1080px) {
      width: 60%;
    }
    @media (max-width: 769px) {
      width: 80%;
    }
    button {
      background-color: $main-color;
      color: $secondary-color;
      border: none;
      border-radius: calc(0.1rem + 1vw);
      padding: 10px 20px;
      cursor: pointer;
      &:hover {
        background-color: $secondary-color;
        color: $main-color;
      }
    }
    .input-row {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      input {
        width: 100%;
        color: $secondary-color;
        background-color: $background-color;
        border: none;
        border-bottom: 2px solid $secondary-color;
        padding-bottom: 5px;
        outline: none;
        &:active {
          border: none;
          border-bottom: 2px solid $main-color;
        }
      }
    }
  }
}
