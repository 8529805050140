@import '../../assets/style/variables.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap');

.apropos {
  padding: 30px;
  img {
    float: left;
    width: 500px;
    height: 500px;
    border-radius: 50px;
    padding: 20px;
    @media (max-width: 850px) {
      width: 250px;
      height: 250px;
    }
  }

  .apropos-container {
    width: 100%;
    h1 {
      padding: 20px;
      font-size: 3rem;
      @media (max-width: 450px) {
        font-size: 2rem;
      }
    }

    p {
      padding: 20px;
      font-size: 1.5rem;
      overflow-wrap: break-word;
      @media (max-width: 450px) {
        font-size: 1rem;
      }
      span {
        font-size: 2rem;
        font-family: 'Cinzel Decorative', serif;
        font-weight: bold;
        padding-right: 5px;
        @media (max-width: 450px) {
          font-size: 1.5rem;
        }
      }
    }

    .button-download {
      margin-left: 20px;
      padding: 15px 10px;
      background-color: $main-color;
      color: $secondary-color;
      border-radius: 20px;
      border: none;
      text-transform: uppercase;
      font-weight: 500;
      &:hover {
        background-color: $background-color;
      }
    }
  }
}
