@import '../../assets/style/variables.module.scss';

.black-header {
  position: absolute;
  background-color: black;
  margin-top: -20vh;
  height: 20vh;
  width: 20vh;
  width: 100%;
  z-index: 1;
}
