@import '../../assets/style/variables.module.scss';

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding-top: 64px;
  padding-bottom: 30px;
  background-image: url(../../assets/img/footer-texture.jpg);
  position: relative;
  border-top: 4px solid $main-color;
  img {
    height: 100px;
    object-fit: contain;
  }
  p {
    color: #fff;
    font-size: 24px;
    padding-top: 30px;
    text-align: center;
    @media (max-width: 769px) {
      font-size: 12px;
      width: 50%;
    }
  }

  .banner {
    position: absolute;
    height: 10%;
    background-image: url(../../assets/img/footer-texture-transparent.png);
    background-color: $main-color;
    background-blend-mode: overlay;
  }

  .banner-top-right {
    top: 30%;
    right: 0;
    width: 15%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 10px;
  }

  .banner-top-left {
    top: 30%;
    width: 15%;
    left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 10px;
  }

  .banner-bottom-right {
    bottom: 30%;
    right: 0;
    width: 20%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 20px;
  }

  .banner-bottom-left {
    bottom: 30%;
    left: 0;
    width: 20%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 20px;
  }
}
