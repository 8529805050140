@import '../../assets/style/variables.module.scss';

.collapse {
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  @media (max-width: 769px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .collapse-header {
    width: 100%;
    .collapse-title {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      width: 50%;
      margin: auto;
      background-color: $main-color;
      color: $secondary-color;
      border-radius: 20px;
      @media (max-width: 769px) {
        width: 100%;
        padding: 15px;
      }

      .arrow {
        width: 15px;
        height: 15px;
      }

      .arrow-reversed {
        transform: rotate(-0.5turn);
        width: 15px;
        height: 15px;
      }
    }
  }

  .collapse-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: black;
    font-size: 18px;
    border-radius: 20px;
    p {
      padding-bottom: 20px;
    }
    img {
      width: auto;
      height: 700px;
      @media (max-width: 769px) {
        width: 100%;
        height: 400px;
      }
    }
  }
}
